<template>
  <div class="promotion-new">
    <div class="font-weight-bold mb-3">
      <router-link class="disabled--text" to="/promotions">Promotions</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">Create new promotion</span>
    </div>
    <v-card outlined min-height="70vh" color="d-flex flex-column">
      <div class="pl-lg-9 px-sm-7 px-3">
        <span class="font-weight-bold me-5">Is active</span>
        <v-switch color="primary" class="white--text d-inline-block" v-model="data.is_active" inset></v-switch>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="text-h6 font-weight-bold">Promotion details</div>
        <div class="text--text mb-3">Provide the description in Enlish and in Arabic.</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.name_en"
            placeholder="Name"
            :error-messages="nameEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.name_ar"
            placeholder="عنوان"
            :error-messages="nameArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data.description_en"
            placeholder="Description"
            :error-messages="desEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف كامل"
            :error-messages="desArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div class="font-weight-bold mb-3">Promotion type</div>
        <v-select
          class="field46"
          v-model="data.type"
          placeholder="Select"
          :error-messages="typeErrors"
          :items="itemsType"
          item-text="title"
          item-value="name"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div v-if="data.type == 'next_purchase' || data.type == 'purchase'">
          <div class="font-weight-bold mb-3">Promotion conditions</div>
          <div class="font-weight-bold text-body-2 mb-2">Reward points on promo code activation</div>
          <v-text-field
            class="field46"
            v-model="data.reward_before_purchase"
            :error-messages="beforeErrors"
            placeholder="0"
            hint="Reward points customer will get in case of promo code activation"
            persistent-hint
            outlined
            dense
            required
            type="number"
            color="primary"
            height="46"
          ></v-text-field>
          <div class="mt-5 font-weight-bold text-body-2 mb-2">Reward points after purchase type</div>
          <v-select
            class="field46"
            v-model="data.reward_after_purchase_type"
            :error-messages="bookingTypeErrors"
            placeholder="Select"
            :items="itemsBookingType"
            item-text="title"
            item-value="name"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-select>
          <div v-if="data.reward_after_purchase_type == 'fixed'">
            <div class="mt-5 font-weight-bold text-body-2 mb-2">Reward points after purchase, fixed</div>
            <v-text-field
              class="field46"
              v-model="data.reward_after_purchase"
              :error-messages="afterErrors"
              placeholder="0"
              hint="Fixed DW reward amount"
              persistent-hint
              outlined
              dense
              required
              type="number"
              color="primary"
              height="46"
            ></v-text-field>
          </div>
          <div v-if="data.reward_after_purchase_type == 'percent'">
            <div class="mt-5 font-weight-bold text-body-2 mb-2">Reward points after purchase, %</div>
            <div class="d-flex flex-md-row flex-column v-input">
              <v-text-field
                class="field46 me-md-3"
                v-model="data.reward_after_purchase"
                :error-messages="afterErrors"
                placeholder="0"
                persistent-hint
                outlined
                dense
                required
                type="number"
                color="primary"
                height="46"
              ></v-text-field>
              <v-text-field
                class="field46 ms-md-3"
                v-model="data.reward_after_purchase_max"
                :error-messages="afterTypeErrors"
                placeholder="max"
                persistent-hint
                outlined
                dense
                required
                type="number"
                color="primary"
                height="46"
              ></v-text-field>
            </div>
            <v-card
              v-if="afterErrors || afterTypeErrors"
              style="line-height: 12px"
              max-width="347"
              flat
              class="text-caption gray--text mt-n6 ms-3"
            >
              Percent of the purchase total amount + max reward amount
            </v-card>
          </div>
          <div class="mt-5 font-weight-bold text-body-2 mb-2">Reward points expire in, days</div>
          <v-text-field
            class="field46"
            v-model="data.reward_duration_days"
            :error-messages="dayErrors"
            placeholder="0"
            hint="Set 0 in case Reward points won't expire"
            persistent-hint
            outlined
            dense
            required
            type="number"
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="font-weight-bold mt-5 mb-3">Availability dates</div>
        <div class="d-flex flex-md-row flex-column v-input">
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_since"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            :min-date="new Date()"
            color="blue"
            class="me-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="From"
                :error-messages="fromErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_till"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            is-required
            :min-date="data.available_since || new Date()"
            color="blue"
            class="ms-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="To"
                :error-messages="toErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
        </div>
        <div class="font-weight-bold mb-3">Photo</div>
        <v-card flat class="primary lighten-3 d-flex align-center justify-center" height="200" max-width="360">
          <div v-if="!image">
            <v-img class="mb-3" contain height="48" src="@/assets/icon/img.svg"></v-img>
            <v-btn @click="addPhoto" depressed width="116" height="30" class="primary text-body-2 rounded">Choose photo</v-btn>
          </div>
          <v-img @click="addPhoto" class="rounded-lg link" v-else contain :src="image" height="200"></v-img>
        </v-card>
        <div class="font-weight-bold mb-3 mt-5">Activation type</div>
        <v-select
          class="field46"
          v-model="data.activation_type"
          placeholder="Select"
          :error-messages="actiTypeErrors"
          :items="itemsActiType"
          item-text="title"
          item-value="name"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-3 mt-5">Available for</div>
        <v-checkbox class="py-2 mt-0" color="success" v-model="data.anb_points_use_required" hide-details>
          <template v-slot:label>
            <div class="ms-2">
              <div class="black--text">ANB points use required</div>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox class="py-2 mt-0" color="success" v-model="data.trip_allowed" hide-details>
          <template v-slot:label>
            <div class="ms-2">
              <div class="black--text">Trip allowed</div>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox class="py-2 mt-0" color="success" v-model="data.travel_experience_allowed" hide-details>
          <template v-slot:label>
            <div class="ms-2">
              <div class="black--text">Travel experience allowed</div>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox class="py-2 mt-0" color="success" v-model="data.gift_voucher_allowed" hide-details>
          <template v-slot:label>
            <div class="ms-2">
              <div class="black--text">Gift voucher allowed</div>
            </div>
          </template>
        </v-checkbox>
      </div>
      <v-spacer></v-spacer>
      <v-divider class="mt-7"></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="createItem" depressed class="rounded primary" large width="136">Create</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      data: {
        is_active: false,
      },
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      image: '',
      itemsType: [
        { name: 'next_purchase', title: 'Next purchase' },
        { name: 'purchase', title: 'Purchase' },
      ],
      itemsActiType: [
        { name: 'code', title: 'Code' },
        { name: 'auto', title: 'Auto' },
      ],
      itemsBookingType: [
        { name: 'fixed', title: 'Fixed' },
        { name: 'percent', title: 'Percent' },
      ],
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Promotion was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'error') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addPhoto() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.data.picture = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async createItem() {
      this.error = [];
      const formData = new FormData();
      this.data.picture ? formData.append('picture', this.data.picture) : '';
      formData.append('is_active', this.data.is_active);
      this.data.name_en ? formData.append('name_en', this.data.name_en) : '';
      this.data.name_ar ? formData.append('name_ar', this.data.name_ar) : '';
      this.data.description_en ? formData.append('description_en', this.data.description_en) : '';
      this.data.description_ar ? formData.append('description_ar', this.data.description_ar) : '';
      this.data.type ? formData.append('type', this.data.type) : '';
      this.data.reward_after_purchase ? formData.append('reward_after_purchase', this.data.reward_after_purchase * 100) : '';
      this.data.reward_after_purchase_type ? formData.append('reward_after_purchase_type', this.data.reward_after_purchase_type) : '';
      this.data.reward_after_purchase_max ? formData.append('reward_after_purchase_max', this.data.reward_after_purchase_max * 100) : '';
      this.data.reward_before_purchase ? formData.append('reward_before_purchase', this.data.reward_before_purchase * 100) : '';
      this.data.reward_duration_days ? formData.append('reward_duration_days', +this.data.reward_duration_days) : '';
      this.data.available_since ? formData.append('available_since', this.data.available_since) : '';
      this.data.available_till ? formData.append('available_till', this.data.available_till) : '';
      this.data.activation_type ? formData.append('activation_type', this.data.activation_type) : '';
      formData.append('anb_points_use_required', this.data.anb_points_use_required ? this.data.anb_points_use_required : false);
      formData.append('trip_allowed', this.data.trip_allowed ? this.data.trip_allowed : false);
      formData.append('travel_experience_allowed', this.data.travel_experience_allowed ? this.data.travel_experience_allowed : false);
      formData.append('gift_voucher_allowed', this.data.gift_voucher_allowed ? this.data.gift_voucher_allowed : false);
      await this.$store
        .dispatch('createPromotion', formData)
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
  },
  computed: {
    nameEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'name_en__required') && errors.push('Please enter name');
      this.error.find((item) => item == 'name_en__invalid') && errors.push('Provided name is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    nameArErrors() {
      const errors = [];
      this.error.find((item) => item == 'name_ar__required') && errors.push('Please enter name');
      this.error.find((item) => item == 'name_ar__invalid') && errors.push('Provided name is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please select type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Selected type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    beforeErrors() {
      const errors = [];
      this.error.find((item) => item == 'reward_before_purchase__required') && errors.push('Please enter reward points');
      this.error.find((item) => item == 'reward_before_purchase__invalid') && errors.push('Provided reward points is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    afterErrors() {
      const errors = [];
      this.error.find((item) => item == 'reward_after_purchase__required') && errors.push('Please enter reward amount');
      this.error.find((item) => item == 'reward_after_purchase__invalid') && errors.push('Provided reward amount is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    afterTypeErrors() {
      const errors = [];
      this.error.find((item) => item == 'reward_after_purchase_max__required') && errors.push('Please enter max reward amount');
      this.error.find((item) => item == 'reward_after_purchase_max__invalid') && errors.push('Provided max reward amount is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    bookingTypeErrors() {
      const errors = [];
      this.error.find((item) => item == 'reward_after_purchase_type__required') && errors.push('Please enter reward type');
      this.error.find((item) => item == 'reward_after_purchase_type__invalid') && errors.push('Provided reward type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    dayErrors() {
      const errors = [];
      this.error.find((item) => item == 'reward_duration_days__required') && errors.push('Please enter reward duration days');
      this.error.find((item) => item == 'reward_duration_days__invalid') && errors.push('Provided reward duration days is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_since__required') && errors.push('Please enter available since');
      this.error.find((item) => item == 'available_since__invalid') && errors.push('Provided available since is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_till__required') && errors.push('Please enter available till');
      this.error.find((item) => item == 'available_till__invalid') && errors.push('Provided available till is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    actiTypeErrors() {
      const errors = [];
      this.error.find((item) => item == 'activation_type__required') && errors.push('Please select activation type');
      this.error.find((item) => item == 'activation_type__invalid') && errors.push('Selected activation type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .promotion-new {
  .v-input {
    max-width: 371px;
  }
}
</style>
